
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: transparent;
}

tr:nth-child(odd) {
  background-color:#eaf6ff;
}

.table-bordered > thead > tr > th:first-child {
  border-top-left-radius: 5px;
}

.table-bordered > thead > tr > th:last-child {
  border-top-right-radius: 5px;
}

.table-bordered {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none !important;
}

.table-bordered td, .table-bordered th {
  border: none !important;
}

.table-bordered > thead > tr > th {
  background-color: #b2d7f2;
}

th.row {
  border: none !important;
}

.table {
  border-radius: 5px;
  margin-top: 1rem;
}

.link {
  font-weight: 500;
  color: #077963 !important;
}

.link:hover {
  color: black !important;
}

@import url(https://fonts.googleapis.com/css?family=Montserrat);

html, body {
    height: 100%;
}

#root {
    display: flex;
    align-content: space-between;
}


#newsletter {
    width: 650px;
    text-align: center;
    font-size: 20px;
    color: #495057;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: var(--background-color);
}

#editor {
   /* width: 500px;
    margin-right: 10%;*/
    width: 100%;
    
}

.form-row {
    padding: 5px 0;
    color: var(--form-control-color) !important;
}

#retrieve-submission {
    width: 500px;
}

.btn {
    font-weight: 600;
    text-align: left !important;
}

.col > .btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--button-background-color);
    margin-top: 8px;
}

.row:last-of-type {
  padding-bottom: 8px;
}

.btns {
    width: 50%;
}

.btns button {
    width: 99%;
}

#newsletter {
    width: 100%;
    overflow: auto;
}

#newsletter-gen {
    width: 45%;
    min-width: 517px;
    overflow: auto;
    background-color: var(--background-color);
}

.header-container, #root  {
  background-color: var(--background-color);
}

.react-datepicker {
   background-color: var(--react-datepicker-background) !important;
   border: none !important;
   box-shadow: 0 0 16px 0px #1d1d1d !important;
   color: var(--react-datepicker-color) !important;
}

.react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker-time__header, .react-datepicker-year-header {
  color: var(--react-datepicker-color) !important;
}

.react-datepicker__header {
  background-color: var(--react-datepicker-header-background) !important;
  border-bottom: none !important;
}

.rdw-editor-toolbar, .rdw-editor-wrapper, .rdw-editor-main {
  background-color: var(--rdw-editor-background)!important;
  border: var(--rdw-editor-border)!important;
}

.rdw-editor-wrapper > .rdw-editor-main {
 box-shadow: var(--rdw-editor-main-border)!important;
}

.rdw-option-wrapper, .rdw-dropdown-wrapper, .rdw-dropdown-optionwrapper, .rdw-dropdownoption-highlighted, .chrome-picker {
  background-color: var(--card-background-color)!important;
  border: var(--color-picker-border)!important;
}

.rdw-dropdownoption-active, .rdw-dropdownoption-default:hover {
  background-color: var(--rdw-dropdown-background) !important;
  border: none !important;
}

.rdw-link-modal-input, .rdw-link-modal {
  background-color: var(--card-background-color)!important;
  color: var(--rc-editable-input) !important;
  box-shadow: var(--rdw-link-modal-box-shadow) !important;
  border: var(--rdw-link-modal-border)!important;
}

.rdw-link-modal-btn {
  background-color: var(--card-background-color)!important;
  color: var(--rc-editable-input) !important;
  box-shadow: var(--rdw-link-modal-box-shadow) !important;
  border: var(--rdw-link-modal-border)!important;
}

#rc-editable-input-1, #rc-editable-input-2, #rc-editable-input-3 {
  color: var(--rc-editable-input) !important;
  background-color: var(--rc-editable-input-background);
  box-shadow: var(--rc-editable-input-box-shadow);
}

.modal-content {
  background-color: var(--modal-content-background) !important;
}

/* Release Date Format(flatpickr)*/
.flatpickr-input {
    background-color: #ffffff !important;
    color: #000000 !important;
}

/* All Style Target For Loader */
/*body {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  font-family: Montserrat;*/
/*}*/

#initial-loader .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#initial-loader .text {
    color: #fbae17;
    display: inline-block;
    margin-left: 5px;
}

#initial-loader .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;
}

#initial-loader .bounceball:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fbae17;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: bounce 500ms alternate infinite ease;
    animation: bounce 500ms alternate infinite ease;
}

@-webkit-keyframes bounce {
    0% {
        top: 30px;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        -webkit-transform: scaleX(2);
        transform: scaleX(2);
    }
    35% {
        height: 15px;
        border-radius: 50%;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}

@keyframes bounce {
    0% {
        top: 30px;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        -webkit-transform: scaleX(2);
        transform: scaleX(2);
    }
    35% {
        height: 15px;
        border-radius: 50%;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}

.rdw-editor-main {
    border: 1px solid #ced4da;
    border-radius: .25rem;
}



.form-check-label {
    display: block;
    position: relative;
   /* padding-left: 25px;*/
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .form-check-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: -22px;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .form-check-label:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .form-check-label input:checked ~ .checkmark {
    background-color: #00aeef;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .form-check-label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .form-check-label .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .row {
    background-color: var(--row-background-color);
  }

  

  .btn:focus {
   /* box-shadow: 0 0 0 0.2rem rgb(160 160 160 / 25%) !important;*/
    box-shadow: none !important;
  }


  .btn.btn-primary {
    color: #fff;
    background-color: #077963;
    border-color: #077963;
  }

  .btns > .btn.btn-primary {
    text-align: center !important;
  }

  .btn.btn-primary:hover {
    background-color: #1d76a3;
  }

 .form-row:last-of-type {
    padding-bottom: 25px;
 }

 .form-row input, .form-row > textarea {
        -moz-box-shadow: inset 0 0 2px #b0b0b0;
        -webkit-box-shadow: inset 0 0 2px #b0b0b0;
        box-shadow: inset 0 0 2px #b0b0b0;
    }

  #newsletter-form > div > select {
   border: none !important;
   background-color: var(--select-background-color) !important;
   color: var(--select-color) !important;
}


.card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/*sliders for checks*/
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  vertical-align: middle;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + span.slider {
  background-color: #077963 !important;
}

/*input:checked + .slider {
  background-color: #00aeef;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00aeef;
}*/

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-check {
    margin-bottom: 12px !important;
}

h4 {
  font-size: 1.4rem !important;
}

.col > .btn:after {
  content: '';
  border: solid black;
  border-width: 0 1px 1px 0;
  margin-left: 10px;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#newsletter-gen, #newsletter {
  background-color: var(--background-color);
}

.form-control {
  background-color: var(--form-control-background) !important;
  border: var(--form-control-border) !important;
  color: var(--form-control-color) !important;
}

.col > button {
  background-color: var(--button-background-color);
  color: var(--button-color);
}

.card {
  background-color: var(--card-background-color) !important;
}

.dark-img {
  display: var(--dark-img-display);
}

.light-img {
  display: var(--light-img-display);
}

h1, h2, p, span, b, td {
  color: var(--span-color);
}

.btn:hover {
  background-color: var(--span-background-hover);
}

.slider {
  background-color: var(--slider-background-color) !important;
}

.slider:before {
  background-color: var(--slider-before-color) !important;
}

iframe {
  border: 1px solid #262626!important;
  background-color: var(--iframe-background-color) !important;
 }

@media (prefers-color-scheme: dark) {
 /* .dark-img {
      display: block !important;
    }

    .light-img {
      display: none !important;
    }*/
  

    /*
 iframe {
  border: 1px solid #262626!important;
  background-color: #191919 !important;
 }

 body {
  background-color: #191919 !important;
 }*/


   
 /* h1, h2, p, span, b, td { color: #677078 !important; }*/
   
  .link { color: #91ADD4 !important; }

  .btn-primary {
    color: #fff;
    background-color: #077963 !important;
    border-color:  #077963 !important;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #0CBC9A !important;
    border-color: #0CBC9A !important;
  }

  .darkmode_body, .em_hide, .em_side10 {
      background-color: #474747 !important;
      color: white !important;
  }

  

  /*
  .form-control {
      background-color: #262626 !important;
      border: none !important;
      color: #677078 !important;
  }*/

 /*.col > button {
    background-color: #333 !important;
    color: #878787 !important;
  }*/
/*
  .card {
    background-color: #212121!important;
  }*/

  /*.header-container, .row, #root  {
      background-color: #191919 !important;
  }*/

  /*.slider {
    background-color: #6d6d6d;
  }*/



  /*.slider:before {
    background-color:#212121!important;
  }*/

   /* Track 
body::-webkit-scrollbar-track {
  background: #424242 !important; 
}
 

body::-webkit-scrollbar-thumb {
  background: #686868 !important; 
}*/

}
