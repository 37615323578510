.link {
    color: dodgerblue;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.link:active {
    color: black;
}

.link:visited {
    color: purple;
}
