:root {
    --font-color: #333;
    --link-color: cornflowerblue;
  }
  

  [data-theme="dark"] {
    --font-color: #eee!important;
    --background-color: #191919;
    --link-color: lightblue!important;
    --form-control-background: #262626 !important;
    --form-control-border: none !important;
    --form-control-color: #9c9c9c !important;
    --button-background-color: #333 !important;
    --button-color: #878787 !important;
    --card-background-color: #474747 !important;
    --dark-img-display: block;
    --light-img-display: none;
    --span-color: #9c9c9c;
    --span-background-hover: #6d6d6d;
    --slider-background-color: #6d6d6d;
    --slider-before-color: #c6c6c6!important;
    --select-background-color: #313131;
    --select-color: #6d6d6d;
    --react-datepicker-background: #4f4f4f;
    --react-datepicker-header-background: #373737;
    --react-datepicker-color: #afafaf;
    --modal-content-background:  #4f4f4f;
    --color-picker-border: none !important;
    --rdw-editor-background: #262626;
    --rdw-editor-border: 1px solid #262626;
    --rdw-dropdown-background: #495057;
    --rc-editable-input: #b0b0b0;
    --rc-editable-input-box-shadow: rgb(110, 110, 110) 0px 0px 0px 1px inset;
    --iframe-background-color: #191919 !important;
    --rdw-editor-main-border: inset 0 0 2px #b0b0b0;
    --rdw-link-modal-box-shadow: 3px 3px 5px #c2c1c1;
    --rdw-link-modal-border: 1px solid #c2c1c1;
  }

  [data-theme="light"] {
    --row-background-color: #efefef !important;
    --form-control-background: #fff !important;
    --background-color: #dbdbdb !important;
    --button-background-color:  #dadada !important;
    --button-color: #878787 !important;
    --button-color: #212529 !important;
    --card-background-color: #fff !important;
    --dark-img-display: none;
    --light-img-display: block;
    --span-color: #212529;
    --span-background-hover: #afafaf;
    --slider-background-color: #ccc;
    --slider-before-color: #fff!important;
    --select-background-color: #d6d6d6;
    --select-color: #6d6d6d;
    --react-datepicker-background: #fff;
    --react-datepicker-header-background: #f0f0f0;
    --rc-editable-input: inherit;
    --rc-editable-input-background: white;
    --rc-editable-input-box-shadow: rgb(218, 218, 218) 0px 0px 0px 1px inset;
    --iframe-background-color: #fff !important;
    --rdw-editor-main-border: inset 0 0 2px #b0b0b0;
    --modal-content-background:  #fff;
    --rdw-link-modal-box-shadow: 3px 3px 5px #c2c1c1;
    --rdw-link-modal-border: 1px solid #c2c1c1;
  }
  

  body {
    background-color: var(--background-color);
    color: var(--font-color);
  }
  
  a {
    color: var(--link-color);
  }

  /* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .toggle-theme-wrapper span {
    font-size: 28px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .toggle-theme {
    position: relative;
    display: inline-block;
    height: 22px;
    width: 45px;
    margin-bottom: 0px !important;
  }
  
  .toggle-theme input {
    display: none;
  }
  
  .theme-slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
  }
  
  .theme-slider:before {
    background-color: #fff;
    bottom: 3px;
    content: "";
    height: 15px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 15px;
  }
  
  input:checked + .theme-slider:before {
    transform: translateX(22px);
  }
  
  input:checked + .theme-slider {
    background-color: #077963; /*was 2d76a3*/
  }
  
  .theme-slider.round {
    border-radius: 34px;
  }
  
  .theme-slider.round:before {
    border-radius: 50%;
  }
